@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	:root {
		--media-brand: 245 245 245;
		--media-focus: 78 156 246;

		--font-sans: Suisse Intl;
		/* --font-mono: here if you got it... */

		/* Interface Colors */
		--white: 255, 255, 255, 1;
		--black-90: 18, 18, 18, 1;

		--black-70: 65, 65, 65, 1;
		--blue-60: 43, 108, 176, 1;

		/* Primary Palette */
		--beige-100: 169, 169, 162, 1;
		--beige-90: 211, 211, 202, 1;
		--beige-10: 237, 237, 231, 1;
		--beige-5: 242, 242, 238, 1;

		/* Secondary Palette */
		--green-90: 11, 28, 18, 1;
		--green-40: 157, 164, 160, 1;
		--mint-green-50: 118, 248, 170, 1;
		--mint-green-80: 118, 248, 170, 0.8;
		--mint-green-60: 94, 198, 136, 1;

		/* Tertiary Palette */
		--red: 204, 95, 95, 1;
		--warning: 255, 153, 0, 1;
		--caution: 242, 224, 115, 1;

		/* Partner Portal Palette */
		--light-grey-90: 178, 178, 178, 1;
		--light-grey-10: 229, 229, 229, 1;

		--button-radius: 4rem;

		/* prefixed with foreground because it should look good on the background */
		--foreground-destructive: 345 82.7% 40.8%;

		--background: 0 0% 100%;
		--foreground: 222.2 84% 4.9%;

		--muted: 210 40% 93%;
		--muted-foreground: 215.4 16.3% 30%;

		--popover: 0 0% 100%;
		--popover-foreground: 222.2 84% 4.9%;

		--card: 0 0% 100%;
		--card-foreground: 222.2 84% 4.9%;

		--border: 214.3 31.8% 91.4%;
		--input: 214.3 31.8% 91.4%;
		--input-invalid: 0 84.2% 60.2%;

		--primary: 222.2 47.4% 11.2%;
		--primary-foreground: 210 40% 98%;

		--secondary: 210 20% 83%;
		--secondary-foreground: 222.2 47.4% 11.2%;

		--accent: 210 40% 90%;
		--accent-foreground: 222.2 47.4% 11.2%;

		--destructive: 0 70% 50%;
		--destructive-foreground: 210 40% 98%;

		--ring: 215 20.2% 65.1%;

		--radius: 0.5rem;

		--plenty-padding: 8.983%;
	}

	.dark {
		--background: 222.2 84% 4.9%;
		--foreground: 210 40% 98%;

		/* prefixed with foreground because it should look good on the background */
		--foreground-destructive: -4 84% 60%;

		--muted: 217.2 32.6% 12%;
		--muted-foreground: 215 20.2% 65.1%;

		--popover: 222.2 84% 4.9%;
		--popover-foreground: 210 40% 98%;

		--card: 222.2 84% 4.9%;
		--card-foreground: 210 40% 98%;

		--border: 217.2 32.6% 17.5%;
		--input: 217.2 32.6% 17.5%;
		--input-invalid: 0 62.8% 30.6%;

		--primary: 210 40% 98%;
		--primary-foreground: 222.2 47.4% 11.2%;

		--secondary: 217.2 20% 24%;
		--secondary-foreground: 210 40% 98%;

		--accent: 217.2 32.6% 10%;
		--accent-foreground: 210 40% 98%;

		--destructive: 0 60% 40%;
		--destructive-foreground: 0 85.7% 97.3%;

		--ring: 217.2 32.6% 60%;
	}
}

@layer base {

	* {
		font-family: 'Suisse Intl', sans-serif;
		@apply border-border;
	}

	/* This is for not having the white space where the scroller is we are testing if it works */
	/* html {
		scrollbar-gutter: stable;
	} */

	body {
		margin: 0;
		@apply bg-background text-foreground;
	}


	body[style] {
		margin-right: 0px !important;
	}

	/* Search Input */
	input[type='search']::-webkit-search-cancel-button {
		-webkit-appearance: none;
		height: 1.3em;
		width: 1.3em;
		border-radius: 50em;
		background: url('../../other/svg-icons/close.svg') no-repeat 50% 50%;
		background-size: contain;
		opacity: 0;
		pointer-events: none;
	}

	input[type='search']:focus::-webkit-search-cancel-button {
		opacity: 1;
		pointer-events: all;
	}

	input[type='search']:hover::-webkit-search-cancel-button {
		cursor: pointer;
	}

	input[type='search'].dark::-webkit-search-cancel-button {
		filter: invert(1);
	}

	.CollapsibleContent[data-state='open'] {
		animation: slideDown 200ms ease-out;
	}

	.CollapsibleContent[data-state='closed'] {
		animation: slideUp 200ms ease-out;
	}

	.no-scroll {
		overflow: hidden;
		scrollbar-width: auto;
	}

	/* Hide scrollbar for the entire document */
	.no-scroll::-webkit-scrollbar {
		display: none;
		scrollbar-width: auto;
		/* For Chrome, Safari, and Opera */
	}

	.no-scroll {
		-ms-overflow-style: none;
		/* For Internet Explorer and Edge */
		scrollbar-width: auto;
		/* For Firefox */
	}

	.special-wrap {
		display: grid;
		grid-template-rows: 1fr;

		&>* {
			grid-column: 1;
			grid-row: 1;
			object-fit: cover;
			/* Ensure images cover the grid area */
			width: 100%;
			height: 100%;
		}
	}
}

.DropdownMenuContent {
	animation-duration: 0.6s;
	animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
	width: var(--radix-dropdown-menu-trigger-width);
	max-height: var(--radix-dropdown-menu-content-available-height);
}

.DropdownMenuContent[data-side='top'] {
	animation-name: slideUp;
}

.DropdownMenuContent[data-side='bottom'] {
	animation-name: slideDown;
}

@keyframes slideUp {
	from {
		opacity: 0;
		transform: translateY(10px);
	}

	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes slideDown {
	from {
		opacity: 0;
		transform: translateY(-10px);
	}

	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@media (min-width: 1025px) {
	.custom-max-width {
		max-width: calc(100% - 36px);
	}
}

/* CSS for the pill for CMS */
.bg-pill-mobile {
	background-image: url('/img/single-pill-blob-left-mobile.svg');
}

@media (min-width: 820px) {
	.bg-pill-mobile {
		background-image: url('/img/single-pill-blob-left-desktop.svg');
	}
}

.bg-triple-pill {
	background-image: url('/img/triple-pill-section.svg');
}